import { useState } from 'react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { withLists, withListsReact } from '@prezly/slate-lists';

import { LISTS_SCHEMA } from './base-editor.constants';
import { withListsFeatures, withCustomFeatures, withEmojiReplacement } from './base-editor.utils';

export const useEditor = () => {
  /* 
    TODO: Come up with an approach for combination
    of decorators. It should allow type inference
  */
  const [editor] = useState(
    withEmojiReplacement(
      withCustomFeatures(
        withListsFeatures(withListsReact(withLists(LISTS_SCHEMA)(withHistory(withReact(createEditor())))))
      )
    )
  );

  return editor;
};
