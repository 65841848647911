export const EMOJI_MAP: Record<string, string> = {
  ':woman_woman_girl_girl:': '👩‍👩‍👧‍👧',
  ':woman_woman_girl_boy:': '👩‍👩‍👧‍👦',
  ':woman_woman_boy_boy:': '👩‍👩‍👦‍👦',
  ':man_woman_girl_girl:': '👨‍👩‍👧‍👧',
  ':man_woman_girl_boy:': '👨‍👩‍👧‍👦',
  ':man_woman_boy_boy:': '👨‍👩‍👦‍👦',
  ':man_man_girl_girl:': '👨‍👨‍👧‍👧',
  ':man_man_girl_boy:': '👨‍👨‍👧‍👦',
  ':man_man_boy_boy:': '👨‍👨‍👦‍👦',
  ':woman_woman_girl:': '👩‍👩‍👧',
  ':woman_woman_boy:': '👩‍👩‍👦',
  ':woman_girl_girl:': '👩‍👧‍👧',
  ':woman_girl_boy:': '👩‍👧‍👦',
  ':woman_boy_boy:': '👩‍👦‍👦',
  ':man_woman_girl:': '👨‍👩‍👧',
  ':man_man_girl:': '👨‍👨‍👧',
  ':man_man_boy:': '👨‍👨‍👦',
  ':man_girl_girl:': '👨‍👧‍👧',
  ':man_girl_boy:': '👨‍👧‍👦',
  ':man_boy_boy:': '👨‍👦‍👦',
  ':woman_girl:': '👩‍👧',
  ':woman_boy:': '👩‍👦',
  ':man_girl:': '👨‍👧',
  ':man_boy:': '👨‍👦',
  ':joy:': '😂',
  ':heart:': '❤️',
  ':heart_suit:': '♥️',
  ':heart_eyes:': '😍',
  ':sob:': '😭',
  ':blush:': '😊',
  ':unamused:': '😒',
  ':kissing_heart:': '😘',
  ':two_hearts:': '💕',
  ':smiling:': '☺️',
  ':weary:': '😩',
  ':dark_skin_tone:': '🏿',
  ':mediumdark_skin_tone:': '🏾',
  ':medium_skin_tone:': '🏽',
  ':mediumlight_skin_tone:': '🏼',
  ':light_skin_tone:': '🏻',
  ':ok_hand:': '👌',
  ':pensive:': '😔',
  ':smirk:': '😏',
  ':grin:': '😁',
  ':recycle:': '♻',
  ':wink:': '😉',
  ':thumbsup:': '👍',
  ':pray:': '🙏',
  ':relieved:': '😌',
  ':notes:': '🎶',
  ':flushed:': '😳',
  ':raised_hands:': '🙌',
  ':see_no_evil:': '🙈',
  ':cry:': '😢',
  ':sunglasses:': '🕶️',
  ':v:': '✌️',
  ':eyes:': '👀',
  ':sweat_smile:': '😅',
  ':sparkles:': '✨',
  ':sleeping:': '😴',
  ':smile:': '😄',
  ':purple_heart:': '💜',
  ':broken_heart:': '💔',
  ':hundred_points:': '💯',
  ':expressionless:': '😑',
  ':sparkling_heart:': '💖',
  ':blue_heart:': '💙',
  ':confused:': '😕',
  ':man_tipping_hand:': '💁‍♂️',
  ':information_desk_person:': '💁',
  ':stuck_out_tongue_winking_eye:': '😜',
  ':disappointed:': '😞',
  ':yum:': '😋',
  ':neutral_face:': '😐',
  ':sleepy:': '😪',
  ':clap:': '👏',
  ':cupid:': '💘',
  ':heartpulse:': '💗',
  ':revolving_hearts:': '💞',
  ':arrow_left:': '⬅️',
  ':speak_no_evil:': '🙊',
  ':raised_hand:': '✋',
  ':kiss:': '💋',
  ':point_right:': '👉',
  ':cherry_blossom:': '🌸',
  ':scream:': '😱',
  ':fire:': '🔥',
  ':rage:': '😡',
  ':smiley:': '😃',
  ':tada:': '🎉',
  ':oncoming_fist:': '👊',
  ':tired_face:': '😫',
  ':camera:': '📷',
  ':rose:': '🌹',
  ':stuck_out_tongue_closed_eyes:': '😝',
  ':muscle:': '💪',
  ':skull:': '💀',
  ':sunny:': '☀️',
  ':yellow_heart:': '💛',
  ':triumph:': '😤',
  ':new_moon_with_face:': '🌚',
  ':laughing:': '😆',
  ':sweat:': '😓',
  ':point_left:': '👈',
  ':heavy_check_mark:': '✔️',
  ':heart_eyes_cat:': '😻',
  ':grinning:': '😀',
  ':mask:': '😷',
  ':green_heart:': '💚',
  ':wave:': '👋',
  ':persevere:': '😣',
  ':heartbeat:': '💓',
  ':arrow_forward:': '▶️',
  ':arrow_backward:': '◀️',
  ':arrow_right_hook:': '↪️',
  ':leftwards_arrow_with_hook:': '↩️',
  ':crown:': '👑',
  ':kissing_closed_eyes:': '😚',
  ':stuck_out_tongue:': '😛',
  ':disappointed_relieved:': '😥',
  ':innocent:': '😇',
  ':headphones:': '🎧',
  ':white_check_mark:': '✅',
  ':confounded:': '😖',
  ':arrow_right:': '➡',
  ':angry:': '😠',
  ':grimacing:': '😬',
  ':star2:': '🌟',
  ':gun:': '🔫',
  ':man_raising_hand:': '🙋‍♂️',
  ':raising_hand:': '🙋',
  ':thumbsdown:': '👎',
  ':dancer:': '💃',
  ':musical_note:': '🎵',
  ':no_mouth:': '😶',
  ':dizzy:': '💫',
  ':fist:': '✊',
  ':point_down:': '👇',
  ':red_circle:': '🔴',
  ':man_gesturing_NO:': '🙅‍♂️',
  ':no_good:': '🙅',
  ':boom:': '💥',
  ':copyright:': '©',
  ':thought_balloon:': '💭',
  ':tongue:': '👅',
  ':poop:': '💩',
  ':cold_sweat:': '😰',
  ':gem:': '💎',
  ':man_gesturing_OK:': '🙆‍♂️',
  ':ok_woman:': '🙆',
  ':pizza:': '🍕',
  ':joy_cat:': '😹',
  ':sun_with_face:': '🌞',
  ':leaves:': '🍃',
  ':sweat_drops:': '💦',
  ':penguin:': '🐧',
  ':zzz:': '💤',
  ':woman_walking:': '🚶‍♀️',
  ':walking:': '🚶',
  ':airplane:': '✈',
  ':balloon:': '🎈',
  ':star:': '⭐',
  ':ribbon:': '🎀',
  ':ballot_box_with_check:': '☑️',
  ':worried:': '😟',
  ':underage:': '🔞',
  ':fearful:': '😨',
  ':four_leaf_clover:': '🍀',
  ':hibiscus:': '🌺',
  ':microphone:': '🎤',
  ':open_hands:': '👐',
  ':ghost:': '👻',
  ':palm_tree:': '🌴',
  ':bangbang:': '‼️',
  ':nail_care:': '💅',
  ':x:': '❌',
  ':alien:': '👽',
  ':woman_bowing:': '🙇‍♀️',
  ':bow:': '🙇',
  ':cloud:': '☁️',
  ':soccer:': '⚽',
  ':angel:': '👼',
  ':men_with_bunny_ears:': '👯‍♂️',
  ':dancers:': '👯',
  ':exclamation:': '❗',
  ':snowflake:': '❄',
  ':point_up:': '☝️',
  ':kissing_smiling_eyes:': '😙',
  ':rainbow:': '🌈',
  ':crescent_moon:': '🌙',
  ':heart_decoration:': '💟',
  ':gift_heart:': '💝',
  ':gift:': '🎁',
  ':beers:': '🍻',
  ':anguished:': '😧',
  ':earth_africa:': '🌍',
  ':movie_camera:': '🎥',
  ':anchor:': '⚓',
  ':zap:': '⚡',
  ':club_suit:': '♣️',
  ':heavy_multiplication_x:': '✖️',
  ':woman_running:': '🏃‍♀️',
  ':runner:': '🏃',
  ':sunflower:': '🌻',
  ':earth_americas:': '🌎',
  ':bouquet:': '💐',
  ':dog:': '🐶',
  ':moneybag:': '💰',
  ':herb:': '🌿',
  ':couple:': '👫',
  ':fallen_leaf:': '🍂',
  ':tulip:': '🌷',
  ':birthday:': '🎂',
  ':cat:': '🐱',
  ':coffee:': '☕',
  ':dizzy_face:': '😵',
  ':point_up_2:': '👆',
  ':open_mouth:': '😮',
  ':hushed:': '😯',
  ':basketball:': '🏀',
  ':christmas_tree:': '🎄',
  ':ring:': '💍',
  ':full_moon_with_face:': '🌝',
  ':astonished:': '😲',
  ':two_women_holding_hands:': '👭',
  ':money_with_wings:': '💸',
  ':crying_cat_face:': '😿',
  ':hear_no_evil:': '🙉',
  ':dash:': '💨',
  ':cactus:': '🌵',
  ':hotsprings:': '♨️',
  ':telephone:': '☎',
  ':maple_leaf:': '🍁',
  ':princess:': '👸',
  ':man_getting_massage:': '💆‍♂️',
  ':massage:': '💆',
  ':love_letter:': '💌',
  ':trophy:': '🏆',
  ':man_frowning:': '🙍‍♂️',
  ':person_frowning:': '🙍',
  ':us:': '🇺🇸',
  ':confetti_ball:': '🎊',
  ':blossom:': '🌼',
  ':kitchen_knife:': '🔪',
  ':lips:': '👄',
  ':fries:': '🍟',
  ':doughnut:': '🍩',
  ':frowning:': '😦',
  ':ocean:': '🌊',
  ':bomb:': '💣',
  ':ok:': '🆗',
  ':cyclone:': '🌀',
  ':rocket:': '🚀',
  ':umbrella:': '☂',
  ':couplekiss:': '💏',
  ':couple_woman_kiss:': '👩‍❤️‍💋‍👩',
  ':couple_man_kiss:': '👨‍❤️‍💋‍👨',
  ':couple_with_heart:': '💑',
  ':woman_woman:': '👩‍❤‍👩',
  ':man_man_love:': '👨‍❤️‍👨',
  ':lollipop:': '🍭',
  ':clapper:': '🎬',
  ':pig:': '🐷',
  ':smiling_imp:': '😈',
  ':imp:': '👿',
  ':bee:': '🐝',
  ':kissing_cat:': '😽',
  ':anger:': '💢',
  ':musical_score:': '🎼',
  ':santa:': '🎅',
  ':earth_asia:': '🌏',
  ':football:': '🏈',
  ':guitar:': '🎸',
  ':diamond_suit:': '♦️',
  ':panda_face:': '🐼',
  ':speech_balloon:': '💬',
  ':strawberry:': '🍓',
  ':smirk_cat:': '😼',
  ':banana:': '🍌',
  ':watermelon:': '🍉',
  ':snowman:': '☃',
  ':smile_cat:': '😸',
  ':spade_suit:': '♠️',
  ':top:': '🔝',
  ':eggplant:': '🍆',
  ':crystal_ball:': '🔮',
  ':fork_and_knife:': '🍴',
  ':calling:': '📲',
  ':iphone:': '📱',
  ':partly_sunny:': '⛅',
  ':warning:': '⚠',
  ':scream_cat:': '🙀',
  ':small_orange_diamond:': '🔸',
  ':baby:': '👶',
  ':feet:': '🐾',
  ':footprints:': '👣',
  ':beer:': '🍺',
  ':wine_glass:': '🍷',
  ':o:': '⭕',
  ':video_camera:': '📹',
  ':rabbit:': '🐰',
  ':tropical_drink:': '🍹',
  ':smoking:': '🚬',
  ':space_invader:': '👾',
  ':peach:': '🍑',
  ':snake:': '🐍',
  ':turtle:': '🐢',
  ':cherries:': '🍒',
  ':kissing:': '😗',
  ':frog:': '🐸',
  ':milky_way:': '🌌',
  ':rotating_light:': '🚨',
  ':hatching_chick:': '🐣',
  ':closed_book:': '📕',
  ':candy:': '🍬',
  ':hamburger:': '🍔',
  ':bear:': '🐻',
  ':tiger:': '🐯',
  ':automobile:': '🚗',
  ':fast_forward:': '⏩',
  ':icecream:': '🍦',
  ':pineapple:': '🍍',
  ':ear_of_rice:': '🌾',
  ':syringe:': '💉',
  ':put_litter_in_its_place:': '🚮',
  ':chocolate_bar:': '🍫',
  ':black_small_square:': '▪',
  ':tv:': '📺',
  ':pill:': '💊',
  ':octopus:': '🐙',
  ':jack_o_lantern:': '🎃',
  ':grapes:': '🍇',
  ':smiley_cat:': '😺',
  ':cd:': '💿',
  ':cocktail:': '🍸',
  ':cake:': '🍰',
  ':video_game:': '🎮',
  ':trade_mark:': '™️',
  ':arrow_down:': '⬇️',
  ':no_entry_sign:': '🚫',
  ':lipstick:': '💄',
  ':whale:': '🐳',
  ':memo:': '📝',
  ':registered:': '®',
  ':cookie:': '🍪',
  ':dolphin:': '🐬',
  ':loud_sound:': '🔊',
  ':man:': '👨',
  ':hatched_chick:': '🐥',
  ':monkey:': '🐒',
  ':books:': '📚',
  ':japanese_ogre:': '👹',
  ':woman_guard:': '💂‍♀️',
  ':guardsman:': '💂',
  ':loudspeaker:': '📢',
  ':scissors:': '✂',
  ':girl:': '👧',
  ':mortar_board:': '🎓',
  ':fr:': '🇫🇷',
  ':baseball:': '⚾',
  ':vertical_traffic_light:': '🚦',
  ':woman:': '👩',
  ':fireworks:': '🎆',
  ':stars:': '🌠',
  ':sos:': '🆘',
  ':mushroom:': '🍄',
  ':pouting_cat:': '😾',
  ':left_luggage:': '🛅',
  ':high_heel:': '👠',
  ':dart:': '🎯',
  ':woman_swimming:': '🏊‍♀️',
  ':swimmer:': '🏊',
  ':key:': '🔑',
  ':bikini:': '👙',
  ':family:': '👪',
  ':pencil2:': '✏',
  ':elephant:': '🐘',
  ':droplet:': '💧',
  ':seedling:': '🌱',
  ':apple:': '🍎',
  ':cool:': '🆒',
  ':telephone_receiver:': '📞',
  ':dollar:': '💵',
  ':house_with_garden:': '🏡',
  ':book:': '📖',
  ':man_getting_haircut:': '💇‍♂️',
  ':haircut:': '💇',
  ':computer:': '💻',
  ':bulb:': '💡',
  ':question:': '❓',
  ':back:': '🔙',
  ':boy:': '👦',
  ':closed_lock_with_key:': '🔐',
  ':man_pouting:': '🙎‍♂️',
  ':person_with_pouting_face:': '🙎',
  ':tangerine:': '🍊',
  ':leftright_arrow:': '↔️',
  ':sunrise:': '🌅',
  ':poultry_leg:': '🍗',
  ':blue_circle:': '🔵',
  ':oncoming_automobile:': '🚘',
  ':shaved_ice:': '🍧',
  ':it:': '🇮🇹',
  ':bird:': '🐦',
  ':gb:': '🇬🇧',
  ':first_quarter_moon_with_face:': '🌛',
  ':eyeglasses:': '👓',
  ':goat:': '🐐',
  ':night_with_stars:': '🌃',
  ':older_woman:': '👵',
  ':black_circle:': '⚫',
  ':new_moon:': '🌑',
  ':two_men_holding_hands:': '👬',
  ':white_circle:': '⚪',
  ':customs:': '🛃',
  ':tropical_fish:': '🐠',
  ':house:': '🏠',
  ':arrows_clockwise:': '🔃',
  ':last_quarter_moon_with_face:': '🌜',
  ':round_pushpin:': '📍',
  ':full_moon:': '🌕',
  ':athletic_shoe:': '👟',
  ':lemon:': '🍋',
  ':baby_bottle:': '🍼',
  ':artist_palette:': '🎨',
  ':envelope:': '✉',
  ':spaghetti:': '🍝',
  ':wind_chime:': '🎐',
  ':fish_cake:': '🍥',
  ':evergreen_tree:': '🌲',
  ':up:': '🆙',
  ':arrow_up:': '⬆️',
  ':arrow_upper_right:': '↗️',
  ':arrow_lower_right:': '↘️',
  ':arrow_lower_left:': '↙️',
  ':performing_arts:': '🎭',
  ':nose:': '👃',
  ':pig_nose:': '🐽',
  ':fish:': '🐟',
  ':woman_wearing_turban:': '👳‍♀️',
  ':man_with_turban:': '👳',
  ':koala:': '🐨',
  ':ear:': '👂',
  ':eight_spoked_asterisk:': '✳️',
  ':small_blue_diamond:': '🔹',
  ':shower:': '🚿',
  ':bug:': '🐛',
  ':ramen:': '🍜',
  ':tophat:': '🎩',
  ':bride_with_veil:': '👰',
  ':fuelpump:': '⛽',
  ':checkered_flag:': '🏁',
  ':horse:': '🐴',
  ':watch:': '⌚',
  ':monkey_face:': '🐵',
  ':baby_symbol:': '🚼',
  ':new:': '🆕',
  ':free:': '🆓',
  ':sparkler:': '🎇',
  ':corn:': '🌽',
  ':tennis:': '🎾',
  ':alarm_clock:': '⏰',
  ':battery:': '🔋',
  ':grey_exclamation:': '❕',
  ':wolf:': '🐺',
  ':moyai:': '🗿',
  ':cow:': '🐮',
  ':mega:': '📣',
  ':older_man:': '👴',
  ':dress:': '👗',
  ':link:': '🔗',
  ':chicken:': '🐔',
  ':cooking:': '🍳',
  ':whale2:': '🐋',
  ':arrow_upper_left:': '↖',
  ':deciduous_tree:': '🌳',
  ':bento:': '🍱',
  ':pushpin:': '📌',
  ':soon:': '🔜',
  ':repeat:': '🔁',
  ':dragon:': '🐉',
  ':hamster:': '🐹',
  ':golf:': '⛳',
  ':woman_surfing:': '🏄‍♀️',
  ':surfer:': '🏄',
  ':mouse:': '🐭',
  ':waxing_crescent_moon:': '🌒',
  ':blue_car:': '🚙',
  ':a:': '🅰️',
  ':interrobang:': '⁉️',
  ':u5272:': '🈹',
  ':electric_plug:': '🔌',
  ':first_quarter_moon:': '🌓',
  ':cancer:': '♋',
  ':trident:': '🔱',
  ':bread:': '🍞',
  ':woman_police_officer:': '👮‍♀️',
  ':cop:': '👮',
  ':tea:': '🍵',
  ':fishing_pole_and_fish:': '🎣',
  ':waxing_gibbous_moon:': '🌔',
  ':bike:': '🚲',
  ':bust_in_silhouette:': '👤',
  ':rice:': '🍚',
  ':radio:': '📻',
  ':baby_chick:': '🐤',
  ':arrow_heading_down:': '⤵️',
  ':waning_crescent_moon:': '🌘',
  ':arrow_up_down:': '↕',
  '': '#',
  ':last_quarter_moon:': '🌗',
  ':radio_button:': '🔘',
  ':sheep:': '🐑',
  ':dark_skin_tone_blond_hair:': '👱🏿‍♂',
  ':mediumdark_skin_tone_blond_hair:': '👱🏾‍♂',
  ':medium_skin_tone_blond_hair:': '👱🏽‍♂',
  ':mediumlight_skin_tone_blond_hair:': '👱🏼‍♂',
  ':light_skin_tone_blond_hair:': '👱🏻‍♂',
  ':blond_hair:': '👱‍♂',
  ':person_with_blond_hair:': '👱',
  ':waning_gibbous_moon:': '🌖',
  ':lock:': '🔒',
  ':green_apple:': '🍏',
  ':japanese_goblin:': '👺',
  ':curly_loop:': '➰',
  ':triangular_flag_on_post:': '🚩',
  ':arrows_counterclockwise:': '🔄',
  ':racehorse:': '🐎',
  ':fried_shrimp:': '🍤',
  ':sunrise_over_mountains:': '🌄',
  ':volcano:': '🌋',
  ':rooster:': '🐓',
  ':inbox_tray:': '📥',
  ':wedding:': '💒',
  ':sushi:': '🍣',
  ':wavy_dash:': '〰️',
  ':ice_cream:': '🍨',
  ':rewind:': '⏪',
  ':tomato:': '🍅',
  ':rabbit2:': '🐇',
  ':eight_pointed_black_star:': '✴️',
  ':small_red_triangle:': '🔺',
  ':high_brightness:': '🔆',
  ':heavy_plus_sign:': '➕',
  ':man_with_gua_pi_mao:': '👲',
  ':convenience_store:': '🏪',
  ':busts_in_silhouette:': '👥',
  ':beetle:': '🐞',
  ':small_red_triangle_down:': '🔻',
  ':ger:': '🇩🇪',
  ':arrow_heading_up:': '⤴️',
  ':name_badge:': '📛',
  ':bath:': '🛀',
  ':no_entry:': '⛔',
  ':crocodile:': '🐊',
  ':chestnut:': '🌰',
  ':dog2:': '🐕',
  ':cat2:': '🐈',
  ':hammer:': '🔨',
  ':meat_on_bone:': '🍖',
  ':shell:': '🐚',
  ':sparkle:': '❇',
  ':sailboat:': '⛵',
  ':b:': '🅱️',
  ':m:': 'Ⓜ️',
  ':poodle:': '🐩',
  ':aquarius:': '♒',
  ':stew:': '🍲',
  ':jeans:': '👖',
  ':honey_pot:': '🍯',
  ':musical_keyboard:': '🎹',
  ':unlock:': '🔓',
  ':black_nib:': '✒️',
  ':statue_of_liberty:': '🗽',
  ':heavy_dollar_sign:': '💲',
  ':snowboarder:': '🏂',
  ':white_flower:': '💮',
  ':necktie:': '👔',
  ':diamond_shape_with_a_dot_inside:': '💠',
  ':aries:': '♈',
  ':womens:': '🚺',
  ':ant:': '🐜',
  ':scorpius:': '♏',
  ':city_sunset:': '🌇',
  ':hourglass_flowing_sand:': '⏳',
  ':o2:': '🅾️',
  ':dragon_face:': '🐲',
  ':snail:': '🐌',
  ':dvd:': '📀',
  ':shirt:': '👕',
  ':game_die:': '🎲',
  ':heavy_minus_sign:': '➖',
  ':dolls:': '🎎',
  ':sagittarius:': '♐',
  ':8ball:': '🎱',
  ':bus:': '🚌',
  ':custard:': '🍮',
  ':crossed_flags:': '🎌',
  ':part_alternation_mark:': '〽',
  ':camel:': '🐫',
  ':curry:': '🍛',
  ':steam_locomotive:': '🚂',
  ':hospital:': '🏥',
  ':jp:': '🇯🇵',
  ':large_blue_diamond:': '🔷',
  ':tanabata_tree:': '🎋',
  ':bell:': '🔔',
  ':leo:': '♌',
  ':gemini:': '♊',
  ':pear:': '🍐',
  ':large_orange_diamond:': '🔶',
  ':taurus:': '♉',
  ':globe_with_meridians:': '🌐',
  ':door:': '🚪',
  ':clock6:': '🕕',
  ':oncoming_police_car:': '🚔',
  ':envelope_with_arrow:': '📩',
  ':closed_umbrella:': '🌂',
  ':saxophone:': '🎷',
  ':church:': '⛪',
  ':woman_biking:': '🚴‍♀️',
  ':bicyclist:': '🚴',
  ':pisces:': '♓',
  ':dango:': '🍡',
  ':capricorn:': '♑',
  ':office:': '🏢',
  ':woman_rowing_boat:': '🚣‍♀️',
  ':rowboat:': '🚣',
  ':womans_hat:': '👒',
  ':mans_shoe:': '👞',
  ':love_hotel:': '🏩',
  ':mount_fuji:': '🗻',
  ':dromedary_camel:': '🐪',
  ':handbag:': '👜',
  ':hourglass:': '⌛',
  ':negative_squared_cross_mark:': '❎',
  ':trumpet:': '🎺',
  ':school:': '🏫',
  ':cow2:': '🐄',
  ':cityscape_at_dusk:': '🌆',
  ':woman_construction_worker:': '👷‍♀️',
  ':construction_worker:': '👷',
  ':toilet:': '🚽',
  ':pig2:': '🐖',
  ':grey_question:': '❔',
  ':beginner:': '🔰',
  ':violin:': '🎻',
  ':on:': '🔛',
  ':credit_card:': '💳',
  ':id:': '🆔',
  ':secret:': '㊙',
  ':ferris_wheel:': '🎡',
  ':bowling:': '🎳',
  ':libra:': '♎',
  ':virgo:': '♍',
  ':barber:': '💈',
  ':purse:': '👛',
  ':roller_coaster:': '🎢',
  ':rat:': '🐀',
  ':date:': '📅',
  ':rugby_football:': '🏉',
  ':ram:': '🐏',
  ':arrow_up_small:': '🔼',
  ':black_square_button:': '🔲',
  ':mobile_phone_off:': '📴',
  ':tokyo_tower:': '🗼',
  ':congratulations:': '㊗',
  ':kimono:': '👘',
  ':ru:': '🇷🇺',
  ':ship:': '🚢',
  ':mag_right:': '🔎',
  ':mag:': '🔍',
  ':fire_engine:': '🚒',
  ':clock1130:': '🕦',
  ':police_car:': '🚓',
  ':black_joker:': '🃏',
  ':bridge_at_night:': '🌉',
  ':package:': '📦',
  ':oncoming_taxi:': '🚖',
  ':calendar:': '📆',
  ':horse_racing:': '🏇',
  ':tiger2:': '🐅',
  ':boot:': '👢',
  ':ambulance:': '🚑',
  ':white_square_button:': '🔳',
  ':boar:': '🐗',
  ':school_satchel:': '🎒',
  ':loop:': '➿',
  ':pound:': '💷',
  ':information_source:': 'ℹ',
  ':ox:': '🐂',
  ':rice_ball:': '🍙',
  ':vs:': '🆚',
  ':end:': '🔚',
  ':parking:': '🅿️',
  ':sandal:': '👡',
  ':tent:': '⛺',
  ':seat:': '💺',
  ':taxi:': '🚕',
  ':black_medium_small_square:': '◾',
  ':briefcase:': '💼',
  ':newspaper:': '📰',
  ':circus_tent:': '🎪',
  ':six_pointed_star:': '🔯',
  ':mens:': '🚹',
  ':european_castle:': '🏰',
  ':flashlight:': '🔦',
  ':foggy:': '🌁',
  ':arrow_double_up:': '⏫',
  ':bamboo:': '🎍',
  ':ticket:': '🎫',
  ':helicopter:': '🚁',
  ':minidisc:': '💽',
  ':oncoming_bus:': '🚍',
  ':melon:': '🍈',
  ':white_small_square:': '▫️',
  ':european_post_office:': '🏤',
  ':keycap_ten:': '🔟',
  ':notebook:': '📓',
  ':no_bell:': '🔕',
  ':oden:': '🍢',
  ':flags:': '🎏',
  ':carousel_horse:': '🎠',
  ':blowfish:': '🐡',
  ':chart_with_upwards_trend:': '📈',
  ':sweet_potato:': '🍠',
  ':ski:': '🎿',
  ':clock12:': '🕛',
  ':signal_strength:': '📶',
  ':construction:': '🚧',
  ':black_medium_square:': '◼',
  ':satellite:': '🛰️',
  ':euro:': '💶',
  ':womans_clothes:': '👚',
  ':ledger:': '📒',
  ':leopard:': '🐆',
  ':low_brightness:': '🔅',
  ':clock3:': '🕒',
  ':department_store:': '🏬',
  ':truck:': '🚚',
  ':sake:': '🍶',
  ':railway_car:': '🚃',
  ':speedboat:': '🚤',
  ':ko:': '🇰🇷',
  ':vhs:': '📼',
  ':clock1:': '🕐',
  ':arrow_double_down:': '⏬',
  ':water_buffalo:': '🐃',
  ':arrow_down_small:': '🔽',
  ':yen:': '💴',
  ':mute:': '🔇',
  ':running_shirt_with_sash:': '🎽',
  ':white_large_square:': '⬜',
  ':wheelchair:': '♿',
  ':clock2:': '🕑',
  ':paperclip:': '📎',
  ':atm:': '🏧',
  ':cinema:': '🎦',
  ':telescope:': '🔭',
  ':rice_scene:': '🎑',
  ':blue_book:': '📘',
  ':white_medium_square:': '◻️',
  ':postbox:': '📮',
  ':e-mail:': '📧',
  ':mouse2:': '🐁',
  ':bullettrain_side:': '🚄',
  ':ideograph_advantage:': '🉐',
  ':nut_and_bolt:': '🔩',
  ':ng:': '🆖',
  ':hotel:': '🏨',
  ':wc:': '🚾',
  ':izakaya_lantern:': '🏮',
  ':repeat_one:': '🔂',
  ':mailbox_with_mail:': '📬',
  ':chart_with_downwards_trend:': '📉',
  ':green_book:': '📗',
  ':tractor:': '🚜',
  ':fountain:': '⛲',
  ':metro:': '🚇',
  ':clipboard:': '📋',
  ':no_mobile_phones:': '📵',
  ':clock4:': '🕓',
  ':no_smoking:': '🚭',
  ':black_large_square:': '⬛',
  ':slot_machine:': '🎰',
  ':clock5:': '🕔',
  ':bathtub:': '🛁',
  ':scroll:': '📜',
  ':station:': '🚉',
  ':rice_cracker:': '🍘',
  ':bank:': '🏦',
  ':wrench:': '🔧',
  ':u6307:': '🈯️',
  ':articulated_lorry:': '🚛',
  ':page_facing_up:': '📄',
  ':ophiuchus:': '⛎',
  ':bar_chart:': '📊',
  ':no_pedestrians:': '🚷',
  ':cn:': '🇨🇳',
  ':vibration_mode:': '📳',
  ':clock10:': '🕙',
  ':clock9:': '🕘',
  ':bullettrain_front:': '🚅',
  ':minibus:': '🚐',
  ':tram:': '🚊',
  ':clock8:': '🕗',
  ':u7a7a:': '🈳',
  ':traffic_light:': '🚥',
  ':woman_mountain_biking:': '🚵‍♀️',
  ':mountain_bicyclist:': '🚵',
  ':microscope:': '🔬',
  ':japanese_castle:': '🏯',
  ':bookmark:': '🔖',
  ':bookmark_tabs:': '📑',
  ':pouch:': '👝',
  ':ab:': '🆎',
  ':page_with_curl:': '📃',
  ':flower_playing_cards:': '🎴',
  ':clock11:': '🕚',
  ':fax:': '📠',
  ':clock7:': '🕖',
  ':white_medium_small_square:': '◽',
  ':currency_exchange:': '💱',
  ':sound:': '🔉',
  ':chart:': '💹',
  ':cl:': '🆑',
  ':floppy_disk:': '💾',
  ':post_office:': '🏣',
  ':speaker:': '🔈',
  ':japan:': '🗾',
  ':u55b6:': '🈺',
  ':mahjong:': '🀄',
  ':incoming_envelope:': '📨',
  ':orange_book:': '📙',
  ':restroom:': '🚻',
  ':u7121:': '🈚️',
  ':u6709:': '🈶',
  ':triangular_ruler:': '📐',
  ':train:': '🚋',
  ':u7533:': '🈸',
  ':trolleybus:': '🚎',
  ':u6708:': '🈷',
  ':input_numbers:': '🔢',
  ':notebook_with_decorative_cover:': '📔',
  ':u7981:': '🈲',
  ':u6e80:': '🈵',
  ':postal_horn:': '📯',
  ':factory:': '🏭',
  ':children_crossing:': '🚸',
  ':train2:': '🚆',
  ':straight_ruler:': '📏',
  ':pager:': '📟',
  ':accept:': '🉑',
  ':u5408:': '🈴',
  ':lock_with_ink_pen:': '🔏',
  ':clock130:': '🕜',
  ':sa:': '🈂️',
  ':outbox_tray:': '📤',
  ':twisted_rightwards_arrows:': '🔀',
  ':mailbox:': '📫',
  ':light_rail:': '🚈',
  ':clock930:': '🕤',
  ':busstop:': '🚏',
  ':open_file_folder:': '📂',
  ':file_folder:': '📁',
  ':potable_water:': '🚰',
  ':card_index:': '📇',
  ':clock230:': '🕝',
  ':monorail:': '🚝',
  ':clock1230:': '🕧',
  ':clock1030:': '🕥',
  ':abc:': '🔤',
  ':mailbox_closed:': '📪',
  ':clock430:': '🕟',
  ':mountain_railway:': '🚞',
  ':do_not_litter:': '🚯',
  ':clock330:': '🕞',
  ':heavy_division_sign:': '➗',
  ':clock730:': '🕢',
  ':clock530:': '🕠',
  ':capital_abcd:': '🔠',
  ':mailbox_with_no_mail:': '📭',
  ':symbols:': '🔣',
  ':aerial_tramway:': '🚡',
  ':clock830:': '🕣',
  ':clock630:': '🕡',
  ':abcd:': '🔡',
  ':mountain_cableway:': '🚠',
  ':koko:': '🈁',
  ':passport_control:': '🛂',
  ':non-potable_water:': '🚱',
  ':suspension_railway:': '🚟',
  ':baggage_claim:': '🛄',
  ':no_bicycles:': '🚳',
  ':rainbow_flag:': '🏳️‍🌈',
  ':woman_detective:': '🕵️‍♀',
  ':detective:': '🕵️',
  ':frowning_face:': '☹️',
  ':skull_crossbones:': '☠',
  ':hugging:': '🤗',
  ':robot:': '🤖',
  ':face_with_headbandage:': '🤕',
  ':thinking:': '🤔',
  ':nerd:': '🤓',
  ':face_with_thermometer:': '🤒',
  ':moneymouth_face:': '🤑',
  ':zipper_mouth:': '🤐',
  ':rolling_eyes:': '🙄',
  ':upside_down:': '🙃',
  ':slight_smile:': '🙂',
  ':slightly_frowning_face:': '🙁',
  ':sign_of_the_horns:': '🤘',
  ':vulcan_salute:': '🖖',
  ':middle_finger:': '🖕',
  ':hand_with_fingers_splayed:': '🖐️',
  ':writing_hand:': '✍️',
  ':dark_sunglasses:': '🕶',
  ':eye_speachbubble:': '👁‍🗨',
  ':eye:': '👁️',
  ':weightlifter_woman_dt:': '🏋🏿‍♀',
  ':weightlifter_woman_mdt:': '🏋🏾‍♀',
  ':weightlifter_woman_mt:': '🏋🏽‍♀',
  ':weightlifter_woman_mlt:': '🏋🏼‍♀',
  ':weightlifter_woman_lt:': '🏋🏻‍♀',
  ':weightlifter_woman:': '🏋‍♀',
  ':weightlifter_dt:': '🏋🏿',
  ':weightlifter_mdt:': '🏋🏾',
  ':weightlifter_mt:': '🏋🏽',
  ':weightlifter_mlt:': '🏋🏼',
  ':weightlifter_lt:': '🏋🏻',
  ':weightlifter:': '🏋',
  ':basketballer_woman_dt:': '⛹🏿‍♀',
  ':basketballer_woman_mdt:': '⛹🏾‍♀',
  ':basketballer_woman_mt:': '⛹🏽‍♀',
  ':basketballer_woman_mlt:': '⛹🏼‍♀',
  ':basketballer_woman_lt:': '⛹🏻‍♀',
  ':woman_bouncing_ball:': '⛹️‍♀',
  ':basketballer_lt:': '⛹🏻',
  ':basketballer:': '⛹',
  ':man_in_suit:': '🕴',
  ':golfer:': '🏌',
  ':golfer_woman:': '🏌‍♀',
  '*': '*️⃣',
  ':heart_exclamation:': '❣',
  ':star_of_david:': '✡️',
  ':cross:': '✝️',
  ':fleur-de-lis:': '⚜',
  ':atom:': '⚛',
  ':wheel_of_dharma:': '☸️',
  ':yin_yang:': '☯️',
  ':peace:': '☮',
  ':star_and_crescent:': '☪️',
  ':orthodox_cross:': '☦️',
  ':biohazard:': '☣️',
  ':radioactive:': '☢️',
  ':place_of_worship:': '🛐',
  ':anger_right:': '🗯',
  ':menorah:': '🕎',
  ':om_symbol:': '🕉',
  ':funeral_urn:': '⚱️',
  ':coffin:': '⚰️',
  ':gear:': '⚙️',
  ':alembic:': '⚗️',
  ':scales:': '⚖',
  ':crossed_swords:': '⚔️',
  ':keyboard:': '⌨️',
  ':oil_drum:': '🛢️',
  ':shield:': '🛡️',
  ':hammer_and_wrench:': '🛠️',
  ':bed:': '🛏️',
  ':bellhop_bell:': '🛎️',
  ':shopping_bags:': '🛍️',
  ':sleeping_accommodation:': '🛌',
  ':couch_and_lamp:': '🛋️',
  ':ballot_box:': '🗳',
  ':dagger:': '🗡️',
  ':rolledup_newspaper:': '🗞️',
  ':old_key:': '🗝️',
  ':compression:': '🗜',
  ':spiral_calendar:': '🗓️',
  ':spiral_notepad:': '🗒️',
  ':wastebasket:': '🗑️',
  ':file_cabinet:': '🗄️',
  ':card_file_box:': '🗃️',
  ':card_index_dividers:': '🗂️',
  ':framed_picture:': '🖼️',
  ':trackball:': '🖲️',
  ':computer_mouse:': '🖱️',
  ':printer:': '🖨️',
  ':desktop_computer:': '🖥️',
  ':crayon:': '🖍️',
  ':paintbrush:': '🖌️',
  ':fountain_pen:': '🖋️',
  ':pen:': '🖊️',
  ':linked_paperclips:': '🖇️',
  ':joystick:': '🕹️',
  ':hole:': '🕳️',
  ':mantelpiece_clock:': '🕰️',
  ':candle:': '🕯️',
  ':prayer_beads:': '📿',
  ':film_projector:': '📽️',
  ':camera_with_flash:': '📸',
  ':amphora:': '🏺',
  ':label:': '🏷️',
  ':flag_black:': '🏴',
  ':flag_white:': '🏳',
  ':film_frames:': '🎞️',
  ':control_knobs:': '🎛️',
  ':level_slider:': '🎚️',
  ':studio_microphone:': '🎙️',
  ':thermometer:': '🌡️',
  ':passenger_ship:': '🛳️',
  ':airplane_arriving:': '🛬',
  ':airplane_departure:': '🛫',
  ':small_airplane:': '🛩️',
  ':motor_boat:': '🛥️',
  ':railway_track:': '🛤️',
  ':motorway:': '🛣️',
  ':world_map:': '🗺️',
  ':synagogue:': '🕍',
  ':mosque:': '🕌',
  ':kaaba:': '🕋',
  ':stadium:': '🏟️',
  ':national_park:': '🏞️',
  ':desert_island:': '🏝️',
  ':desert:': '🏜️',
  ':classical_building:': '🏛️',
  ':derelict_house:': '🏚️',
  ':cityscape:': '🏙️',
  ':houses:': '🏘️',
  ':building_construction:': '🏗️',
  ':beach_with_umbrella:': '🏖️',
  ':camping:': '🏕️',
  ':snowcapped_mountain:': '🏔️',
  ':racing_car:': '🏎️',
  ':motorcycle:': '🏍️',
  ':bow_and_arrow:': '🏹',
  ':badminton:': '🏸',
  ':rosette:': '🏵️',
  ':ping_pong:': '🏓',
  ':ice_hockey:': '🏒',
  ':field_hockey:': '🏑',
  ':volleyball:': '🏐',
  ':cricket_game:': '🏏',
  ':medal:': '🏅',
  ':admission_tickets:': '🎟️',
  ':reminder_ribbon:': '🎗️',
  ':military_medal:': '🎖️',
  ':cheese_wedge:': '🧀',
  ':popcorn:': '🍿',
  ':champagne:': '🍾',
  ':fork_and_knife_with_plate:': '🍽️',
  ':hot_pepper:': '🌶️',
  ':burrito:': '🌯',
  ':taco:': '🌮',
  ':hotdog:': '🌭',
  ':shamrock:': '☘️',
  ':comet:': '☄️',
  ':unicorn:': '🦄',
  ':turkey:': '🦃',
  ':scorpion:': '🦂',
  ':lion_face:': '🦁',
  ':crab:': '🦀',
  ':spider_web:': '🕸️',
  ':spider:': '🕷️',
  ':dove:': '🕊️',
  ':chipmunk:': '🐿️',
  ':wind_blowing_face:': '🌬',
  ':fog:': '🌫️',
  ':tornado:': '🌪️',
  ':cloud_with_lightning:': '🌩️',
  ':cloud_with_snow:': '🌨️',
  ':cloud_with_rain:': '🌧️',
  ':sun_behind_rain_cloud:': '🌦️',
  ':sun_behind_large_cloud:': '🌥️',
  ':sun_behind_small_cloud:': '🌤️',
  ':speaking_head:': '🗣️',
  ':record_button:': '⏺️',
  ':stop_button:': '⏹️',
  ':pause_button:': '⏸️',
  ':play_pause:': '⏯',
  ':track_previous:': '⏮',
  ':track_next:': '⏭',
  ':beach_umbrella:': '⛱',
  ':chains:': '⛓️',
  ':pick:': '⛏️',
  ':hammer_and_pick:': '⚒️',
  ':timer_clock:': '⏲️',
  ':stopwatch:': '⏱️',
  ':ferry:': '⛴️',
  ':mountain:': '⛰️',
  ':shinto_shrine:': '⛩️',
  ':ice_skate:': '⛸️',
  ':skier:': '⛷️',
  ':cloud_with_lightning_and_rain:': '⛈️',
  ':rescue_worker’s_helmet:': '⛑️',
  ':flag_ac:': '🇦🇨',
  ':flag_ad:': '🇦🇩',
  ':flag_ae:': '🇦🇪',
  ':flag_af:': '🇦🇫',
  ':flag_ag:': '🇦🇬',
  ':flag_ai:': '🇦🇮',
  ':flag_al:': '🇦🇱',
  ':flag_am:': '🇦🇲',
  ':flag-ao:': '🇦🇴',
  ':flag-aq:': '🇦🇶',
  ':flag-ar:': '🇦🇷',
  ':flag-as:': '🇦🇸',
  ':flag-at:': '🇦🇹',
  ':flag-au:': '🇦🇺',
  ':flag-aw:': '🇦🇼',
  ':flag-ax:': '🇦🇽',
  ':flag-az:': '🇦🇿',
  ':flag-ba:': '🇧🇦',
  ':flag-bb:': '🇧🇧',
  ':flag-bd:': '🇧🇩',
  ':flag-be:': '🇧🇪',
  ':flag-bf:': '🇧🇫',
  ':flag-bg:': '🇧🇬',
  ':flag-bh:': '🇧🇭',
  ':flag-bi:': '🇧🇮',
  ':flag-bj:': '🇧🇯',
  ':flag-bl:': '🇧🇱',
  ':flag-bm:': '🇧🇲',
  ':flag-bn:': '🇧🇳',
  ':flag-bo:': '🇧🇴',
  ':flag-bq:': '🇧🇶',
  ':flag-br:': '🇧🇷',
  ':flag-bs:': '🇧🇸',
  ':flag-bt:': '🇧🇹',
  ':flag-bv:': '🇧🇻',
  ':flag-bw:': '🇧🇼',
  ':flag-by:': '🇧🇾',
  ':flag-bz:': '🇧🇿',
  ':flag-ca:': '🇨🇦',
  ':flag-cc:': '🇨🇨',
  ':flag-cd:': '🇨🇩',
  ':flag-cf:': '🇨🇫',
  ':flag-cg:': '🇨🇬',
  ':flag-ch:': '🇨🇭',
  ':flag-ci:': '🇨🇮',
  ':flag-ck:': '🇨🇰',
  ':flag-cl:': '🇨🇱',
  ':flag-cm:': '🇨🇲',
  ':flag-cn:': '🇨🇳',
  ':flag-co:': '🇨🇴',
  ':flag-cp:': '🇨🇵',
  ':flag-cr:': '🇨🇷',
  ':flag-cu:': '🇨🇺',
  ':flag-cv:': '🇨🇻',
  ':flag-cw:': '🇨🇼',
  ':flag-cx:': '🇨🇽',
  ':flag-cy:': '🇨🇾',
  ':flag-cz:': '🇨🇿',
  ':flag-de:': '🇩🇪',
  ':flag-dg:': '🇩🇬',
  ':flag-dj:': '🇩🇯',
  ':flag-dk:': '🇩🇰',
  ':flag-dm:': '🇩🇲',
  ':flag-do:': '🇩🇴',
  ':flag-dz:': '🇩🇿',
  ':flag-ea:': '🇪🇦',
  ':flag-ec:': '🇪🇨',
  ':flag-ee:': '🇪🇪',
  ':flag-eg:': '🇪🇬',
  ':flag-eh:': '🇪🇭',
  ':flag-er:': '🇪🇷',
  ':flag-es:': '🇪🇸',
  ':flag-et:': '🇪🇹',
  ':flag-eu:': '🇪🇺',
  ':flag-fi:': '🇫🇮',
  ':flag-fj:': '🇫🇯',
  ':flag-fk:': '🇫🇰',
  ':flag-fm:': '🇫🇲',
  ':flag-fo:': '🇫🇴',
  ':flag-fr:': '🇫🇷',
  ':flag-ga:': '🇬🇦',
  ':flag-gb:': '🇬🇧',
  ':flag-gd:': '🇬🇩',
  ':flag-ge:': '🇬🇪',
  ':flag-gf:': '🇬🇫',
  ':flag-gg:': '🇬🇬',
  ':flag-gh:': '🇬🇭',
  ':flag-gi:': '🇬🇮',
  ':flag-gl:': '🇬🇱',
  ':flag-gm:': '🇬🇲',
  ':flag-gn:': '🇬🇳',
  ':flag-gp:': '🇬🇵',
  ':flag-gq:': '🇬🇶',
  ':flag-gr:': '🇬🇷',
  ':flag-gs:': '🇬🇸',
  ':flag-gt:': '🇬🇹',
  ':flag-gu:': '🇬🇺',
  ':flag-gw:': '🇬🇼',
  ':flag-gy:': '🇬🇾',
  ':flag-hk:': '🇭🇰',
  ':flag-hm:': '🇭🇲',
  ':flag-hn:': '🇭🇳',
  ':flag-hr:': '🇭🇷',
  ':flag-ht:': '🇭🇹',
  ':flag-hu:': '🇭🇺',
  ':flag-ic:': '🇮🇨',
  ':flag-id:': '🇮🇩',
  ':flag-ie:': '🇮🇪',
  ':flag-il:': '🇮🇱',
  ':flag-im:': '🇮🇲',
  ':flag-in:': '🇮🇳',
  ':flag-io:': '🇮🇴',
  ':flag-iq:': '🇮🇶',
  ':flag-ir:': '🇮🇷',
  ':flag-is:': '🇮🇸',
  ':flag-it:': '🇮🇹',
  ':flag-je:': '🇯🇪',
  ':flag-jm:': '🇯🇲',
  ':flag-jo:': '🇯🇴',
  ':flag-jp:': '🇯🇵',
  ':flag-ke:': '🇰🇪',
  ':flag-kg:': '🇰🇬',
  ':flag-kh:': '🇰🇭',
  ':flag-ki:': '🇰🇮',
  ':flag-km:': '🇰🇲',
  ':flag-kn:': '🇰🇳',
  ':flag-kp:': '🇰🇵',
  ':flag-kr:': '🇰🇷',
  ':flag-kw:': '🇰🇼',
  ':flag-ky:': '🇰🇾',
  ':flag-kz:': '🇰🇿',
  ':flag-la:': '🇱🇦',
  ':flag-lb:': '🇱🇧',
  ':flag-lc:': '🇱🇨',
  ':flag-li:': '🇱🇮',
  ':flag-lk:': '🇱🇰',
  ':flag-lr:': '🇱🇷',
  ':flag-ls:': '🇱🇸',
  ':flag-lt:': '🇱🇹',
  ':flag-lu:': '🇱🇺',
  ':flag-lv:': '🇱🇻',
  ':flag-ly:': '🇱🇾',
  ':flag-ma:': '🇲🇦',
  ':flag-mc:': '🇲🇨',
  ':flag-md:': '🇲🇩',
  ':flag-me:': '🇲🇪',
  ':flag-mf:': '🇲🇫',
  ':flag-mg:': '🇲🇬',
  ':flag-mh:': '🇲🇭',
  ':flag-mk:': '🇲🇰',
  ':flag-ml:': '🇲🇱',
  ':flag-mm:': '🇲🇲',
  ':flag-mn:': '🇲🇳',
  ':flag-mo:': '🇲🇴',
  ':flag-mp:': '🇲🇵',
  ':flag-mq:': '🇲🇶',
  ':flag-mr:': '🇲🇷',
  ':flag-ms:': '🇲🇸',
  ':flag-mt:': '🇲🇹',
  ':flag-mu:': '🇲🇺',
  ':flag-mv:': '🇲🇻',
  ':flag-mw:': '🇲🇼',
  ':flag-mx:': '🇲🇽',
  ':flag-my:': '🇲🇾',
  ':flag-mz:': '🇲🇿',
  ':flag-na:': '🇳🇦',
  ':flag-nc:': '🇳🇨',
  ':flag-ne:': '🇳🇪',
  ':flag-nf:': '🇳🇫',
  ':flag-ng:': '🇳🇬',
  ':flag-ni:': '🇳🇮',
  ':flag-nl:': '🇳🇱',
  ':flag-no:': '🇳🇴',
  ':flag-np:': '🇳🇵',
  ':flag-nr:': '🇳🇷',
  ':flag-nu:': '🇳🇺',
  ':flag-nz:': '🇳🇿',
  ':flag-om:': '🇴🇲',
  ':flag-pa:': '🇵🇦',
  ':flag-pe:': '🇵🇪',
  ':flag-pf:': '🇵🇫',
  ':flag-pg:': '🇵🇬',
  ':flag-ph:': '🇵🇭',
  ':flag-pk:': '🇵🇰',
  ':flag-pl:': '🇵🇱',
  ':flag-pm:': '🇵🇲',
  ':flag-pn:': '🇵🇳',
  ':flag-pr:': '🇵🇷',
  ':flag-ps:': '🇵🇸',
  ':flag-pt:': '🇵🇹',
  ':flag-pw:': '🇵🇼',
  ':flag-py:': '🇵🇾',
  ':flag-qa:': '🇶🇦',
  ':flag-re:': '🇷🇪',
  ':flag-ro:': '🇷🇴',
  ':flag-rs:': '🇷🇸',
  ':flag-ru:': '🇷🇺',
  ':flag-rw:': '🇷🇼',
  ':flag-sa:': '🇸🇦',
  ':flag-sb:': '🇸🇧',
  ':flag-sc:': '🇸🇨',
  ':flag-sd:': '🇸🇩',
  ':flag-se:': '🇸🇪',
  ':flag-sg:': '🇸🇬',
  ':flag-sh:': '🇸🇭',
  ':flag-si:': '🇸🇮',
  ':flag-sj:': '🇸🇯',
  ':flag-sk:': '🇸🇰',
  ':flag-sl:': '🇸🇱',
  ':flag-sm:': '🇸🇲',
  ':flag-sn:': '🇸🇳',
  ':flag-so:': '🇸🇴',
  ':flag-sr:': '🇸🇷',
  ':flag-ss:': '🇸🇸',
  ':flag-st:': '🇸🇹',
  ':flag-sv:': '🇸🇻',
  ':flag-sx:': '🇸🇽',
  ':flag-sy:': '🇸🇾',
  ':flag-sz:': '🇸🇿',
  ':flag-ta:': '🇹🇦',
  ':flag-tc:': '🇹🇨',
  ':flag-td:': '🇹🇩',
  ':flag-tf:': '🇹🇫',
  ':flag-tg:': '🇹🇬',
  ':flag-th:': '🇹🇭',
  ':flag-tj:': '🇹🇯',
  ':flag-tk:': '🇹🇰',
  ':flag-tl:': '🇹🇱',
  ':flag-tm:': '🇹🇲',
  ':flag-tn:': '🇹🇳',
  ':flag-to:': '🇹🇴',
  ':flag-tr:': '🇹🇷',
  ':flag-tt:': '🇹🇹',
  ':flag-tv:': '🇹🇻',
  ':flag-tw:': '🇹🇼',
  ':flag-tz:': '🇹🇿',
  ':flag-ua:': '🇺🇦',
  ':flag-ug:': '🇺🇬',
  ':flag-um:': '🇺🇲',
  ':flag-us:': '🇺🇸',
  ':flag-uy:': '🇺🇾',
  ':flag-uz:': '🇺🇿',
  ':flag-va:': '🇻🇦',
  ':flag-vc:': '🇻🇨',
  ':flag-ve:': '🇻🇪',
  ':flag-vg:': '🇻🇬',
  ':flag-vi:': '🇻🇮',
  ':flag-vn:': '🇻🇳',
  ':flag_vu:': '🇻🇺',
  ':flag_wf:': '🇼🇫',
  ':flag_ws:': '🇼🇸',
  ':flag_xk:': '🇽🇰',
  ':flag_ye:': '🇾🇪',
  ':flag_yt:': '🇾🇹',
  ':flag_za:': '🇿🇦',
  ':flag_zm:': '🇿🇲',
  ':flag_zw:': '🇿🇼',
  ':black_heart:': '🖤',
  ':speech_left:': '🗨',
  ':egg:': '🥚',
  ':octagonal_sign:': '🛑',
  ':spades:': '♠',
  ':hearts:': '♥',
  ':diamonds:': '♦',
  ':clubs:': '♣',
  ':drum:': '🥁',
  ':left_right_arrow:': '↔',
  ':tm:': '™',
  ':0:': '0️⃣',
  ':1:': '1️⃣',
  ':2:': '2️⃣',
  ':3:': '3️⃣',
  ':4:': '4️⃣',
  ':5:': '5️⃣',
  ':6:': '6️⃣',
  ':7:': '7️⃣',
  ':8:': '8️⃣',
  ':9:': '9️⃣',
  ':hash:': '#⃣',
  ':asterisk:': '*⃣',
  ':zero:': '0⃣',
  ':one:': '1⃣',
  ':two:': '2⃣',
  ':three:': '3⃣',
  ':four:': '4⃣',
  ':five:': '5⃣',
  ':six:': '6⃣',
  ':seven:': '7⃣',
  ':eight:': '8⃣',
  ':nine:': '9⃣',
  ':rolling_on_the_floor_laughing:': '🤣',
  ':smiling_face_with_hearts:': '🥰',
  ':starstruck:': '🤩',
  ':smiling_face:': '☺',
  ':zany_face:': '🤪',
  ':face_with_hand_over_mouth:': '🤭',
  ':shushing_face:': '🤫',
  ':face_with_raised_eyebrow:': '🤨',
  ':lying_face:': '🤥',
  ':drooling_face:': '🤤',
  ':nauseated_face:': '🤢',
  ':face_vomiting:': '🤮',
  ':sneezing_face:': '🤧',
  ':hot_face:': '🥵',
  ':cold_face:': '🥶',
  ':woozy_face:': '🥴',
  ':exploding_head:': '🤯',
  ':cowboy_hat_face:': '🤠',
  ':partying_face:': '🥳',
  ':face_with_monocle:': '🧐',
  ':pleading_face:': '🥺',
  ':yawning_face:': '🥱',
  ':face_with_symbols_on_mouth:': '🤬',
  ':skull_and_crossbones:': '☠️',
  ':clown_face:': '🤡',
  ':red_heart:': '❤',
  ':orange_heart:': '🧡',
  ':brown_heart:': '🤎',
  ':white_heart:': '🤍',
  ':eye_in_speech_bubble:': '👁️‍🗨',
  ':left_speech_bubble:': '🗨️',
  ':right_anger_bubble:': '🗯️',
  ':raised_back_of_hand:': '🤚',
  ':pinching_hand:': '🤏',
  ':victory_hand:': '✌',
  ':crossed_fingers:': '🤞',
  ':loveyou_gesture:': '🤟',
  ':call_me_hand:': '🤙',
  ':index_pointing_up:': '☝',
  ':leftfacing_fist:': '🤛',
  ':rightfacing_fist:': '🤜',
  ':palms_up_together:': '🤲',
  ':handshake:': '🤝',
  ':selfie:': '🤳',
  ':mechanical_arm:': '🦾',
  ':mechanical_leg:': '🦿',
  ':leg:': '🦵',
  ':foot:': '🦶',
  ':ear_with_hearing_aid:': '🦻',
  ':brain:': '🧠',
  ':tooth:': '🦷',
  ':bone:': '🦴',
  ':child:': '🧒',
  ':person:': '🧑',
  ':beard:': '🧔',
  ':light_skin_tone_beard:': '🧔🏻',
  ':mediumlight_skin_tone_beard:': '🧔🏼',
  ':medium_skin_tone_beard:': '🧔🏽',
  ':mediumdark_skin_tone_beard:': '🧔🏾',
  ':dark_skin_tone_beard:': '🧔🏿',
  ':red_hair:': '🦰',
  ':light_skin_tone_red_hair:': '🧑🏻‍🦰',
  ':mediumlight_skin_tone_red_hair:': '🧑🏼‍🦰',
  ':medium_skin_tone_red_hair:': '🧑🏽‍🦰',
  ':mediumdark_skin_tone_red_hair:': '🧑🏾‍🦰',
  ':dark_skin_tone_red_hair:': '🧑🏿‍🦰',
  ':curly_hair:': '🦱',
  ':light_skin_tone_curly_hair:': '🧑🏻‍🦱',
  ':mediumlight_skin_tone_curly_hair:': '🧑🏼‍🦱',
  ':medium_skin_tone_curly_hair:': '🧑🏽‍🦱',
  ':mediumdark_skin_tone_curly_hair:': '🧑🏾‍🦱',
  ':dark_skin_tone_curly_hair:': '🧑🏿‍🦱',
  ':white_hair:': '🦳',
  ':light_skin_tone_white_hair:': '🧑🏻‍🦳',
  ':mediumlight_skin_tone_white_hair:': '🧑🏼‍🦳',
  ':medium_skin_tone_white_hair:': '🧑🏽‍🦳',
  ':mediumdark_skin_tone_white_hair:': '🧑🏾‍🦳',
  ':dark_skin_tone_white_hair:': '🧑🏿‍🦳',
  ':bald:': '🦲',
  ':light_skin_tone_bald:': '🧑🏻‍🦲',
  ':mediumlight_skin_tone_bald:': '🧑🏼‍🦲',
  ':medium_skin_tone_bald:': '🧑🏽‍🦲',
  ':mediumdark_skin_tone_bald:': '🧑🏾‍🦲',
  ':dark_skin_tone_bald:': '🧑🏿‍🦲',
  ':older_person:': '🧓',
  ':woman_frowning:': '🙍‍♀',
  ':woman_pouting:': '🙎‍♀',
  ':woman_gesturing_NO:': '🙅‍♀',
  ':woman_gesturing_OK:': '🙆‍♀',
  ':woman_tipping_hand:': '💁‍♀',
  ':woman_raising_hand:': '🙋‍♀',
  ':deaf_person:': '🧏',
  ':deaf_man:': '🧏‍♂',
  ':deaf_woman:': '🧏‍♀',
  ':man_bowing:': '🙇‍♂',
  ':person_facepalming:': '🤦',
  ':man_facepalming:': '🤦‍♂',
  ':woman_facepalming:': '🤦‍♀',
  ':person_shrugging:': '🤷',
  ':man_shrugging:': '🤷‍♂',
  ':woman_shrugging:': '🤷‍♀',
  ':health_worker:': '🧑‍⚕',
  ':man_health_worker:': '👨‍⚕',
  ':woman_health_worker:': '👩‍⚕',
  ':student:': '🧑‍🎓',
  ':man_student:': '👨‍🎓',
  ':woman_student:': '👩‍🎓',
  ':teacher:': '🧑‍🏫',
  ':man_teacher:': '👨‍🏫',
  ':woman_teacher:': '👩‍🏫',
  ':judge:': '🧑‍⚖',
  ':man_judge:': '👨‍⚖',
  ':woman_judge:': '👩‍⚖',
  ':farmer:': '🧑‍🌾',
  ':man_farmer:': '👨‍🌾',
  ':woman_farmer:': '👩‍🌾',
  ':cook:': '🧑‍🍳',
  ':man_cook:': '👨‍🍳',
  ':woman_cook:': '👩‍🍳',
  ':mechanic:': '🧑‍🔧',
  ':man_mechanic:': '👨‍🔧',
  ':woman_mechanic:': '👩‍🔧',
  ':factory_worker:': '🧑‍🏭',
  ':man_factory_worker:': '👨‍🏭',
  ':woman_factory_worker:': '👩‍🏭',
  ':office_worker:': '🧑‍💼',
  ':man_office_worker:': '👨‍💼',
  ':woman_office_worker:': '👩‍💼',
  ':scientist:': '🧑‍🔬',
  ':man_scientist:': '👨‍🔬',
  ':woman_scientist:': '👩‍🔬',
  ':technologist:': '🧑‍💻',
  ':man_technologist:': '👨‍💻',
  ':woman_technologist:': '👩‍💻',
  ':singer:': '🧑‍🎤',
  ':man_singer:': '👨‍🎤',
  ':woman_singer:': '👩‍🎤',
  ':artist:': '🧑‍🎨',
  ':man_artist:': '👨‍🎨',
  ':woman_artist:': '👩‍🎨',
  ':pilot:': '🧑‍✈',
  ':man_pilot:': '👨‍✈',
  ':woman_pilot:': '👩‍✈',
  ':astronaut:': '🧑‍🚀',
  ':man_astronaut:': '👨‍🚀',
  ':woman_astronaut:': '👩‍🚀',
  ':firefighter:': '🧑‍🚒',
  ':man_firefighter:': '👨‍🚒',
  ':woman_firefighter:': '👩‍🚒',
  ':man_police_officer:': '👮‍♂',
  ':man_detective:': '🕵‍♂',
  ':man_guard:': '💂‍♂',
  ':man_construction_worker:': '👷‍♂',
  ':prince:': '🤴',
  ':man_wearing_turban:': '👳‍♂',
  ':woman_with_headscarf:': '🧕',
  ':man_in_tuxedo:': '🤵',
  ':pregnant_woman:': '🤰',
  ':breastfeeding:': '🤱',
  ':Mrs_Claus:': '🤶',
  ':superhero:': '🦸',
  ':man_superhero:': '🦸‍♂',
  ':woman_superhero:': '🦸‍♀',
  ':supervillain:': '🦹',
  ':man_supervillain:': '🦹‍♂',
  ':woman_supervillain:': '🦹‍♀',
  ':mage:': '🧙',
  ':man_mage:': '🧙‍♂',
  ':woman_mage:': '🧙‍♀',
  ':fairy:': '🧚',
  ':man_fairy:': '🧚‍♂',
  ':woman_fairy:': '🧚‍♀',
  ':vampire:': '🧛',
  ':man_vampire:': '🧛‍♂',
  ':woman_vampire:': '🧛‍♀',
  ':merperson:': '🧜',
  ':merman:': '🧜‍♂',
  ':mermaid:': '🧜‍♀',
  ':elf:': '🧝',
  ':man_elf:': '🧝‍♂',
  ':woman_elf:': '🧝‍♀',
  ':genie:': '🧞',
  ':man_genie:': '🧞‍♂',
  ':woman_genie:': '🧞‍♀',
  ':zombie:': '🧟',
  ':man_zombie:': '🧟‍♂',
  ':woman_zombie:': '🧟‍♀',
  ':woman_getting_massage:': '💆‍♀',
  ':woman_getting_haircut:': '💇‍♀',
  ':man_walking:': '🚶‍♂',
  ':person_standing:': '🧍',
  ':man_standing:': '🧍‍♂',
  ':woman_standing:': '🧍‍♀',
  ':person_kneeling:': '🧎',
  ':man_kneeling:': '🧎‍♂',
  ':woman_kneeling:': '🧎‍♀',
  ':person_with_probing_cane:': '🧑‍🦯',
  ':man_with_probing_cane:': '👨‍🦯',
  ':woman_with_probing_cane:': '👩‍🦯',
  ':person_in_motorized_wheelchair:': '🧑‍🦼',
  ':man_in_motorized_wheelchair:': '👨‍🦼',
  ':woman_in_motorized_wheelchair:': '👩‍🦼',
  ':person_in_manual_wheelchair:': '🧑‍🦽',
  ':man_in_manual_wheelchair:': '👨‍🦽',
  ':woman_in_manual_wheelchair:': '👩‍🦽',
  ':man_running:': '🏃‍♂',
  ':man_dancing:': '🕺',
  ':man_in_suit_levitating:': '🕴️',
  ':women_with_bunny_ears:': '👯‍♀',
  ':person_in_steamy_room:': '🧖',
  ':man_in_steamy_room:': '🧖‍♂',
  ':woman_in_steamy_room:': '🧖‍♀',
  ':person_climbing:': '🧗',
  ':man_climbing:': '🧗‍♂',
  ':woman_climbing:': '🧗‍♀',
  ':person_fencing:': '🤺',
  ':person_golfing:': '🏌️',
  ':man_golfing:': '🏌‍♂',
  ':woman_golfing:': '🏌️‍♀',
  ':man_surfing:': '🏄‍♂',
  ':man_rowing_boat:': '🚣‍♂',
  ':man_swimming:': '🏊‍♂',
  ':person_bouncing_ball:': '⛹️',
  ':man_bouncing_ball:': '⛹‍♂',
  ':person_lifting_weights:': '🏋️',
  ':man_lifting_weights:': '🏋‍♂',
  ':woman_lifting_weights:': '🏋️‍♀',
  ':man_biking:': '🚴‍♂',
  ':man_mountain_biking:': '🚵‍♂',
  ':person_cartwheeling:': '🤸',
  ':man_cartwheeling:': '🤸‍♂',
  ':woman_cartwheeling:': '🤸‍♀',
  ':people_wrestling:': '🤼',
  ':men_wrestling:': '🤼‍♂',
  ':women_wrestling:': '🤼‍♀',
  ':person_playing_water_polo:': '🤽',
  ':man_playing_water_polo:': '🤽‍♂',
  ':woman_playing_water_polo:': '🤽‍♀',
  ':person_playing_handball:': '🤾',
  ':man_playing_handball:': '🤾‍♂',
  ':woman_playing_handball:': '🤾‍♀',
  ':person_juggling:': '🤹',
  ':man_juggling:': '🤹‍♂',
  ':woman_juggling:': '🤹‍♀',
  ':person_in_lotus_position:': '🧘',
  ':man_in_lotus_position:': '🧘‍♂',
  ':woman_in_lotus_position:': '🧘‍♀',
  ':people_holding_hands:': '🧑‍🤝‍🧑',
  ':light_skin_tone_mediumlight_skin_tone:': '👨🏻‍🤝‍👨🏼',
  ':light_skin_tone_medium_skin_tone:': '👨🏻‍🤝‍👨🏽',
  ':light_skin_tone_mediumdark_skin_tone:': '👨🏻‍🤝‍👨🏾',
  ':light_skin_tone_dark_skin_tone:': '👨🏻‍🤝‍👨🏿',
  ':mediumlight_skin_tone_light_skin_tone:': '👨🏼‍🤝‍👨🏻',
  ':mediumlight_skin_tone_medium_skin_tone:': '👨🏼‍🤝‍👨🏽',
  ':mediumlight_skin_tone_medium-dark_skin_tone:': '👨🏼‍🤝‍👨🏾',
  ':mediumlight_skin_tone_dark_skin_tone:': '👨🏼‍🤝‍👨🏿',
  ':medium_skin_tone_light_skin_tone:': '👨🏽‍🤝‍👨🏻',
  ':medium_skin_tone_mediumlight_skin_tone:': '👨🏽‍🤝‍👨🏼',
  ':medium_skin_tone_mediumdark_skin_tone:': '👨🏽‍🤝‍👨🏾',
  ':medium_skin_tone_dark_skin_tone:': '👨🏽‍🤝‍👨🏿',
  ':mediumdark_skin_tone_light_skin_tone:': '👨🏾‍🤝‍👨🏻',
  ':mediumdark_skin_tone_medium-light_skin_tone:': '👨🏾‍🤝‍👨🏼',
  ':mediumdark_skin_tone_medium_skin_tone:': '👨🏾‍🤝‍👨🏽',
  ':mediumdark_skin_tone_dark_skin_tone:': '👨🏾‍🤝‍👨🏿',
  ':dark_skin_tone_light_skin_tone:': '👨🏿‍🤝‍👨🏻',
  ':dark_skin_tone_mediumlight_skin_tone:': '👨🏿‍🤝‍👨🏼',
  ':dark_skin_tone_medium_skin_tone:': '👨🏿‍🤝‍👨🏽',
  ':dark_skin_tone_mediumdark_skin_tone:': '👨🏿‍🤝‍👨🏾',
  ':woman_man:': '👩‍❤‍👨',
  ':man_man:': '👨‍❤‍👨',
  ':man_woman_boy:': '👨‍👩‍👦',
  ':gorilla:': '🦍',
  ':orangutan:': '🦧',
  ':guide_dog:': '🦮',
  ':service_dog:': '🐕‍🦺',
  ':fox:': '🦊',
  ':raccoon:': '🦝',
  ':zebra:': '🦓',
  ':deer:': '🦌',
  ':llama:': '🦙',
  ':giraffe:': '🦒',
  ':rhinoceros:': '🦏',
  ':hippopotamus:': '🦛',
  ':hedgehog:': '🦔',
  ':bat:': '🦇',
  ':sloth:': '🦥',
  ':otter:': '🦦',
  ':skunk:': '🦨',
  ':kangaroo:': '🦘',
  ':badger:': '🦡',
  ':eagle:': '🦅',
  ':duck:': '🦆',
  ':swan:': '🦢',
  ':owl:': '🦉',
  ':flamingo:': '🦩',
  ':peacock:': '🦚',
  ':parrot:': '🦜',
  ':lizard:': '🦎',
  ':sauropod:': '🦕',
  ':TRex:': '🦖',
  ':shark:': '🦈',
  ':butterfly:': '🦋',
  ':cricket:': '🦗',
  ':mosquito:': '🦟',
  ':microbe:': '🦠',
  ':wilted_flower:': '🥀',
  ':mango:': '🥭',
  ':kiwi_fruit:': '🥝',
  ':coconut:': '🥥',
  ':avocado:': '🥑',
  ':potato:': '🥔',
  ':carrot:': '🥕',
  ':cucumber:': '🥒',
  ':leafy_green:': '🥬',
  ':broccoli:': '🥦',
  ':garlic:': '🧄',
  ':onion:': '🧅',
  ':peanuts:': '🥜',
  ':croissant:': '🥐',
  ':baguette_bread:': '🥖',
  ':pretzel:': '🥨',
  ':bagel:': '🥯',
  ':pancakes:': '🥞',
  ':waffle:': '🧇',
  ':cut_of_meat:': '🥩',
  ':bacon:': '🥓',
  ':sandwich:': '🥪',
  ':stuffed_flatbread:': '🥙',
  ':falafel:': '🧆',
  ':shallow_pan_of_food:': '🥘',
  ':bowl_with_spoon:': '🥣',
  ':green_salad:': '🥗',
  ':butter:': '🧈',
  ':salt:': '🧂',
  ':canned_food:': '🥫',
  ':moon_cake:': '🥮',
  ':dumpling:': '🥟',
  ':fortune_cookie:': '🥠',
  ':takeout_box:': '🥡',
  ':lobster:': '🦞',
  ':shrimp:': '🦐',
  ':squid:': '🦑',
  ':oyster:': '🦪',
  ':cupcake:': '🧁',
  ':pie:': '🥧',
  ':glass_of_milk:': '🥛',
  ':clinking_glasses:': '🥂',
  ':tumbler_glass:': '🥃',
  ':cup_with_straw:': '🥤',
  ':beverage_box:': '🧃',
  ':mate:': '🧉',
  ':ice:': '🧊',
  ':chopsticks:': '🥢',
  ':spoon:': '🥄',
  ':compass:': '🧭',
  ':brick:': '🧱',
  ':hindu_temple:': '🛕',
  ':hot_springs:': '♨',
  ':motor_scooter:': '🛵',
  ':manual_wheelchair:': '🦽',
  ':motorized_wheelchair:': '🦼',
  ':auto_rickshaw:': '🛺',
  ':kick_scooter:': '🛴',
  ':skateboard:': '🛹',
  ':canoe:': '🛶',
  ':parachute:': '🪂',
  ':flying_saucer:': '🛸',
  ':luggage:': '🧳',
  ':sun:': '☀',
  ':ringed_planet:': '🪐',
  ':wind_face:': '🌬️',
  ':umbrella_on_ground:': '⛱️',
  ':firecracker:': '🧨',
  ':red_envelope:': '🧧',
  ':1st_place_medal:': '🥇',
  ':2nd_place_medal:': '🥈',
  ':3rd_place_medal:': '🥉',
  ':softball:': '🥎',
  ':flying_disc:': '🥏',
  ':lacrosse:': '🥍',
  ':boxing_glove:': '🥊',
  ':martial_arts_uniform:': '🥋',
  ':goal_net:': '🥅',
  ':diving_mask:': '🤿',
  ':sled:': '🛷',
  ':curling_stone:': '🥌',
  ':yoyo:': '🪀',
  ':kite:': '🪁',
  ':nazar_amulet:': '🧿',
  ':puzzle_piece:': '🧩',
  ':teddy_bear:': '🧸',
  ':chess_pawn:': '♟',
  ':thread:': '🧵',
  ':yarn:': '🧶',
  ':goggles:': '🥽',
  ':lab_coat:': '🥼',
  ':safety_vest:': '🦺',
  ':scarf:': '🧣',
  ':gloves:': '🧤',
  ':coat:': '🧥',
  ':socks:': '🧦',
  ':sari:': '🥻',
  ':onepiece_swimsuit:': '🩱',
  ':briefs:': '🩲',
  ':shorts:': '🩳',
  ':hiking_boot:': '🥾',
  ':flat_shoe:': '🥿',
  ':ballet_shoes:': '🩰',
  ':billed_cap:': '🧢',
  ':banjo:': '🪕',
  ':abacus:': '🧮',
  ':diya_lamp:': '🪔',
  ':receipt:': '🧾',
  ':ballot_box_with_ballot:': '🗳️',
  ':pencil:': '✏️',
  ':axe:': '🪓',
  ':clamp:': '🗜️',
  ':balance_scale:': '⚖️',
  ':probing_cane:': '🦯',
  ':toolbox:': '🧰',
  ':magnet:': '🧲',
  ':test_tube:': '🧪',
  ':petri_dish:': '🧫',
  ':dna:': '🧬',
  ':drop_of_blood:': '🩸',
  ':adhesive_bandage:': '🩹',
  ':stethoscope:': '🩺',
  ':chair:': '🪑',
  ':razor:': '🪒',
  ':lotion_bottle:': '🧴',
  ':safety_pin:': '🧷',
  ':broom:': '🧹',
  ':basket:': '🧺',
  ':roll_of_paper:': '🧻',
  ':soap:': '🧼',
  ':sponge:': '🧽',
  ':fire_extinguisher:': '🧯',
  ':shopping_cart:': '🛒',
  ':up_arrow:': '⬆',
  ':upright_arrow:': '↗',
  ':right_arrow:': '➡️',
  ':downright_arrow:': '↘',
  ':down_arrow:': '⬇',
  ':downleft_arrow:': '↙',
  ':left_arrow:': '⬅',
  ':upleft_arrow:': '↖️',
  ':updown_arrow:': '↕️',
  ':right_arrow_curving_left:': '↩',
  ':left_arrow_curving_right:': '↪',
  ':right_arrow_curving_up:': '⤴',
  ':right_arrow_curving_down:': '⤵',
  ':atom_symbol:': '⚛️',
  ':om:': '🕉️',
  ':star_of_David:': '✡',
  ':latin_cross:': '✝',
  ':peace_symbol:': '☮️',
  ':play_button:': '▶',
  ':next_track_button:': '⏭️',
  ':play_or_pause_button:': '⏯️',
  ':reverse_button:': '◀',
  ':last_track_button:': '⏮️',
  ':eject_button:': '⏏',
  ':female_sign:': '♀',
  ':male_sign:': '♂',
  ':medical_symbol:': '⚕',
  ':infinity:': '♾',
  ':recycling_symbol:': '♻️',
  ':fleurde-lis:': '⚜️',
  ':check_box_with_check:': '☑',
  ':check_mark:': '✔',
  ':multiplication_sign:': '✖',
  ':eightspoked_asterisk:': '✳',
  ':eightpointed_star:': '✴',
  ':double_exclamation_mark:': '‼',
  ':exclamation_question_mark:': '⁉',
  ':#:': '#️⃣',
  ':A_button_blood_type:': '🅰',
  ':B_button_blood_type:': '🅱',
  ':information:': 'ℹ️',
  ':circled_M:': 'Ⓜ',
  ':O_button_blood_type:': '🅾',
  ':P_button:': '🅿',
  ':Japanese_service_charge_button:': '🈂',
  ':Japanese_monthly_amount_button:': '🈷️',
  ':Japanese_reserved_button:': '🈯',
  ':Japanese_free_of_charge_button:': '🈚',
  ':Japanese_congratulations_button:': '㊗️',
  ':Japanese_secret_button:': '㊙️',
  ':orange_circle:': '🟠',
  ':yellow_circle:': '🟡',
  ':green_circle:': '🟢',
  ':purple_circle:': '🟣',
  ':brown_circle:': '🟤',
  ':red_square:': '🟥',
  ':orange_square:': '🟧',
  ':yellow_square:': '🟨',
  ':green_square:': '🟩',
  ':blue_square:': '🟦',
  ':white_flag:': '🏳️',
  ':pirate_flag:': '🏴‍☠',
  ':United_Nations:': '🇺🇳',
  ':England:': '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  ':Scotland:': '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  ':wales:': '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
};
