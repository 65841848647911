export interface SpecializationBase {
  title: string;
  seniority?: string | null;
  show_in_cv: boolean;
  has_custom_title: boolean;
  candidateID: string;
}

export interface SpecializationUpdateBase extends SpecializationBase {
  id: string;
}

export interface CandidateSpecialization extends SpecializationUpdateBase {
  _id: string;
  created_date: string;
  __v: number;
}

export interface SkillBase {
  name: string;
  years: string | null;
  main: boolean | null;
  show_in_cv: boolean | null;
}
export interface CandidateSkill extends SkillBase {
  _id: string;
  created_date: string;
  __v: number;
  id: string;
  candidateID: string;
}

/*
 TODO: Compelte Candidate interface according to Swagger docs.
*/
export interface Candidate {
  id: string;
  apiKey: string;
  availability_type: string | null;
  available_hours_per_week: string;
  potentiallyAvailableSince?: string | null;
  name?: string;
  firstName: string;
  lastName: string;
  specializations: CandidateSpecialization[];
  mainSkills: CandidateSkill[];
  otherSkills: CandidateSkill[];
  min_rate_per_hour?: number | null;
  max_rate_per_hour?: number | null;
  rateInfo?: string;
  referredById: string | null;
  created_date: string;
  status_change_date: string;
  status_in_system: string;
  country?: string | null;
  role: string | null;
  email: string;
  phone?: string;
  telegram?: string;
  sourceL1: string | null;
  sourceL2: string | null;
  sourceL3: string | null;
  sourceL4?: string | null;
  sourceL5?: string | null;
  team: null | string;
  teamId: null | string;
  client_rate_per_hour: string | number;
  lemonId: string | null;
  /**
   * TODO: ensure this field exists
   */
  chat_url?: string;
  profile_photo: string | null;
  profile_photo_show_in_cv: boolean | null;
  professional_intro?: string | null;
  additional_info?: string;
  linkedin?: string;
  linkedinId?: string;
  link_to_cv?: string;
  github_link?: string;
  codesamples_link?: string;
  slackId?: string | null;
  communityWorkspaceSlackChannelId?: string | null;
  applicationStage?: string | null;
  en_spoken: string;
  telegramGroupUrl: string;
  experience_since: string;
  experience_since_show_in_cv: boolean | null;
  oldestReminderTimestamp?: string;
  oldestReminderComment?: string;
  isHot?: boolean;
  payoneer_name: string | null;
  payment_credentials: string | null;
  w8ben_link: string | null;
  seniority?: string | null;
  city?: string;
  startupExperience?: string | null;
  freelanceExperience?: string | null;
  onboardedToCommunityLogDate?: Date | null;
  lastClosedSubscriptionDate?: Date | null;
  directHirePossible: boolean | null;
  readyToStartWhen?: string | null;
  vacationPlans?: string | null;
  timeSlotsForInterview?: string | null;
  timeZone?: string | null;
  workingHoursFrom?: string | null;
  workingHoursTo?: string | null;
  tagNames?: string[];
  reincarnatedFromContactId?: string;
  languages?: CandidateLanguage[];
}

export interface CandidateProject {
  approved: boolean;
  candidateID: string;
  created_date: string;
  description: string;
  /** as MM YYYY, i.e. May 2016 */
  endDate: string;
  link: string;
  name: string;
  responsibilities: string;
  role: string;
  show_in_cv: boolean;
  /** as MM YYYY, i.e. Aug 2016 */
  startDate: string;
  tech_stack: string;
  id: string;
  weight?: number;
}

export enum ScreeningStage {
  SOFT_SKILLS = 'Interview soft skills/Eng',
  HARD_SKILLS = 'Interview hard skills',
  FINAL_BRIEFING = 'Final briefing',
}

export interface CandidateScreening {
  _id: string;
  authorId: string;
  screening_conductor: string;
  comment: string;
  screening_date: string;
  link: string;
  candidateID: string;
  created_date: string;
  id: string;
  stage: ScreeningStage;
  __v: number;
}

export enum FeedbackSource {
  Client = 'Client',
  Matching = 'Matching',
  Sales = 'Sales',
  ClientSuccess = 'Client Success',
}

export enum FeedbackMood {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
}
export const FeedbackMoodWithEmojis = {
  [FeedbackMood.Negative]: FeedbackMood.Negative + ' 🙁',
  [FeedbackMood.Neutral]: FeedbackMood.Neutral + ' 😐',
  [FeedbackMood.Positive]: FeedbackMood.Positive + ' 🥰',
};
export const FeedbackMoodTitleWithEmojis = {
  [FeedbackMood.Negative]: FeedbackMood.Negative + ' feedback 🙁',
  [FeedbackMood.Neutral]: FeedbackMood.Neutral + ' feedback 😐',
  [FeedbackMood.Positive]: FeedbackMood.Positive + ' feedback 🥰',
};

export interface CandidateFeedback {
  id: string;
  feedbackText: string;
  source: FeedbackSource;
  hubspotProjectId?: string;
  dealId?: string;
  dealName?: string;
  dealPrimaryCompanyName?: string;
  feedbackDate: string;
  contactId: string;
  displayForClients: boolean;
  displayForDev: boolean;
  feedbackMood: FeedbackMood;
  link?: string;
  isProcessed: boolean;
  author: string;
  authorId: string;
  created_date: string;
  subscriptionId?: string;
}

export interface Attachment {
  contactId: string;
  date: string;
  filename: string;
  id: string;
  isUpload: boolean;
  name: string;
  previewUrls: Array<string>;
  url: string;
  type: string;
  __v: number;
  _id: string;
}

export enum CandidateStatus {
  NEW = 'New',
  IN_PROCESS = 'In Process',
  SCREENING_CALL = 'Screening call',
  HARD_SKILLS_INTERVIEW = 'Hard skills interview',
  FINAL_BRIEFING = 'Final briefing',
  AVAILABLE = 'Available',
  TEMPORARY_UNAVAILABLE = 'Temporary unavailable',
  PARTNERS_LISTED = 'Partners listed',
  DISABLED = 'Disabled',
  QUIT = 'Quit',
  FIRED = 'Fired',
  FULLY_ENGAGED = 'Fully engaged with client',
  LONG_LIST = 'Long list',
  REFUSED = 'Refused',
  FOR_FUTURE = 'For future',
  DECLINED = 'Declined',
  OUTREACHED = 'Outreached',
  DELETE = 'Delete',
  ENGAGED = 'Engaged',
}

export enum AvailabilityType {
  PartTimeOnly = 'PartTimeOnly',
  FullTimeOnly = 'FullTimeOnly',
  BothPartAndFullTime = 'BothPartAndFullTime',
  PartTimeCanSwitchToFullTime = 'PartTimeCanSwitchToFullTime',
  Unknown = 'Unknown',
  Unavailable = 'Unavailable',
}

export enum DuplicatedBy {
  fullName = 'fullName',
  email = 'email',
  phone = 'phone',
  nameSynonyms = 'nameSynonyms',
  linkedin = 'linkedin',
}

export interface Duplicate {
  contact: Candidate;
  duplicatedBy: DuplicatedBy[];
}
export interface DuplicatesResponse {
  duplicates: Duplicate[];
  unmarkedDuplicates: Duplicate[];
}
export interface UnmarkDuplicatesResponse {
  contact1: Candidate;
  contact2: Candidate;
}

export interface UnmarkAllDuplicatesResponse {
  contact1: Candidate;
  otherContacts: Candidate[];
}

export interface ReminderBase {
  to?: string;
  datetimeMs: number;
  contactId: string;
  text: string;
  shouldSendSlackNotification?: boolean;
}

export interface Reminder extends ReminderBase {
  completedDatetimeMs?: number;
  createdBy: string;
  id: string;
}

export interface OperatorNote {
  id: string;
  body: string;
  userId: string;
  contactId: string;
  updatedDate: string;
  createdDate: string;
}

export interface Education {
  id: string;
  created_date: string;
  candidateID: string;
  authorId: string;
  university: string;
  field_of_study: string;
  degree: string;
  graduation_year: number;
  show_in_cv: boolean;
}

export type EditableEducation = Pick<
  Education,
  'university' | 'field_of_study' | 'degree' | 'graduation_year' | 'show_in_cv'
>;

export enum CallType {
  ScreeningCall = 'Screening call',
  HardSkillsInterview = 'Hard skills interview',
}

export enum ApplicationStage {
  ApplicationStarted = 'ApplicationStarted',
  VideoAskStarted = 'VideoAskStarted',
  ApplicationReopened = 'ApplicationReopened',
  VideoAskCompleted = 'VideoAskCompleted',
  ProfileSubmittedForReview = 'ProfileSubmittedForReview',
  ScreeningCallInvitationSent = 'ScreeningCallInvitationSent',
  ScreeningCallBooked = 'ScreeningCallBooked',
  ScreeningCallMade = 'ScreeningCallMade',
  HardSkillsInvitationSent = 'HardSkillsInvitationSent',
  HardSkillsInterviewBooked = 'HardSkillsInterviewBooked',
  HardSkillsInterviewMade = 'HardSkillsInterviewMade',
  FinalBriefingStarted = 'FinalBriefingStarted',
  ExperienceSubmittedForReview = 'ExperienceSubmittedForReview',
  OnboardedToCommunity = 'OnboardedToCommunity',
  ApplicationPaused = 'ApplicationPaused',
}

export enum LeadType {
  INBOUND = 'Inbound',
  OUTREACHED = 'Outreached',
  PART_OF_THE_TEAM = 'Part of the team',
}

export enum LeadTypeOptionValue {
  ALL_LEADS = 'ALL_LEADS',
  INBOUND = 'INBOUND',
  OUTREACHED = 'OUTREACHED',
}

export interface CandidateProfileDiff {
  id: string;
  actionType: string;
  authorId?: string;
  authorType: string;
  contactId: string;
  dateCreated: string;
  itemId: string;
  itemType: string;
  newValue: string;
  previousValue: string;
}

export interface DynamicCandidateStatistics {
  daysInTheCommunity: string | null;
  projectsOfferedCount: number | null;
  daysWithoutProjectOffers: number | null;
  activeSubscriptionsCount: number | null;
  maintenanceSubscriptionsCount: number | null;
  activatedSubscriptionsCount: number | null;
  daysWithoutSubscriptions: number | null;
  weakCandidateDeclinesCount: number | null;
  noResponseDeclinesCount: number | null;
  devDeclinedProjectDeclinesCount: number | null;
  devRefusedToContinueDeclinesCount: number | null;
  badFitChurnsCount: number | null;
  devLeftChurnsCount: number | null;
  mismatchedExpectationsChurnsCount: number | null;
  activeProjectOffersCount: number | null;
}

export enum DiffItemType {
  Contact = 'Contact',
  Project = 'Project',
  Education = 'Education',
  Employment = 'Employment',
  Skill = 'Skill',
  Specialization = 'Specialization',
}

export enum DiffActionType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export enum DiffAuthorType {
  User = 'User',
  Contact = 'Contact',
  Migration = 'Migration',
  Lemonizer = 'Lemonizer',
  SlackBot = 'SlackBot',
  AppLemon = 'AppLemon',
  RegistrationForm = 'RegistrationForm',
  Unknown = 'Unknown',
}

export enum LanguageLevel {
  PreIntermediate = 'Pre-intermediate',
  Intermediate = 'Intermediate',
  UpperIntermediate = 'Upper-intermediate',
  Advanced = 'Advanced',
}

export interface CandidateLanguageToUpsert {
  id?: string;
  language: string;
  level: string;
}

export interface CandidateLanguage {
  id: string;
  language: string;
  level: string;
}
