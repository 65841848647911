const ENDPOINTS = {
  MANY_CANDIDATES: 'contacts/find',
  CANDIDATES_STATUSES: 'contacts/find/statuses',
  ONE_CANDIDATE: 'contacts',
  OPERATORS: 'users',
  TEAMS: 'teams',
  HOT_SKILLS: 'hot-skills',
  SKILL_DICTIONARY: 'skills',
  CONSTANTS: 'constants',
  NEXT_STATUSES: 'constants/next-statuses',
  SOURCES: 'sources',
  CONTRACTS: 'check-developer-on-lemon',
  SLACK_USERS: 'slack/users',
  REMAINDERS: 'reminders',
  HUBSPOT_PROJECTS: 'projects',
  TAGS: 'tags',
} as const;

export default ENDPOINTS;
