import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  findHubspotProjectCandidates,
  fetchHubspotProjectCandidates,
  addHubspotProjectCandidate,
  updateHubspotProjectCandidate,
  declineHubspotProjectCandidate,
  UpdateHubspotProjectCandidatePayload,
  approveHubspotProjectCandidate,
  reviewHubspotProjectCandidate,
  restoreHubspotProjectCandidate,
} from './hubspot-project-candidates-service';

export { type UpdateHubspotProjectCandidatePayload };

/* TODO: Move to somewhere so next time it can be reused. */
const generateFoundHubspotProjectCandidateKey = ({
  filters,
  pagination,
}: GetPayload<typeof findHubspotProjectCandidates>) => {
  const objectToSerialize: Record<string, string | number | boolean> = { ...filters, ...pagination };
  return Object.keys(objectToSerialize)
    .reduce<Array<string>>((acc, key) => [...acc, `${key}=${objectToSerialize[key]}`], [])
    .join(';');
};

export const useFindHubspotProjectCandidates = (payload: GetPayload<typeof findHubspotProjectCandidates>) => {
  const result = useQuery(['found-hubspot-project-candidates', generateFoundHubspotProjectCandidateKey(payload)], () =>
    findHubspotProjectCandidates(payload)
  );
  return prepareQueryResult('foundHubspotProjectCandidates', result, null, true);
};

export const useFetchMatchedHubspotProjectCandidates = (hubspotProjectId: string) => {
  const result = useQuery(
    ['matched-hubspot-project-candidates', hubspotProjectId],
    () =>
      fetchHubspotProjectCandidates({
        filters: { hubspotProjectId, isAutoMatchedOrShortlisted: false, isDeclined: false },
      }),
    { staleTime: 0 }
  );
  return prepareQueryResult('matchedHubspotProjectCandidates', result, []);
};
export const useFetchDeclinedHubspotProjectCandidates = (hubspotProjectId: string) => {
  const result = useQuery(
    ['declined-hubspot-project-candidates', hubspotProjectId],
    () => fetchHubspotProjectCandidates({ filters: { hubspotProjectId, isDeclined: true } }),
    { staleTime: 0 }
  );
  return prepareQueryResult('declinedHubspotProjectCandidates', result, []);
};
export const useFetchAutoMatchedHubspotProjectCandidates = (hubspotProjectId: string) => {
  const result = useQuery(
    ['auto-matched-hubspot-project-candidates', hubspotProjectId],
    () =>
      fetchHubspotProjectCandidates({
        filters: { hubspotProjectId, isAutoMatchedOrShortlisted: true, isDeclined: false },
      }),
    { staleTime: 0 }
  );
  return prepareQueryResult('autoMatchedHubspotProjectCandidates', result, []);
};

export const useAddHubspotProjectCandidate = (invalidateAutoMatchedToo = false) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      if (invalidateAutoMatchedToo) {
        queryClient.invalidateQueries(['auto-matched-hubspot-project-candidates', hubspotProjectId]);
      }
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('addHubspotProjectCandidate', mutation);
};

export const useUpdateHubspotProjectCandidate = ({
  invalidateMatched,
  invalidateDeclined,
}: {
  invalidateMatched: boolean;
  invalidateDeclined: boolean;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { fieldsToUpdate: { contactId, hubspotProjectId } }) => {
      if (invalidateMatched) {
        queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      }
      if (invalidateDeclined) {
        queryClient.invalidateQueries(['declined-hubspot-project-candidates', hubspotProjectId]);
      }
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('updateHubspotProjectCandidate', mutation);
};

export const useDeclineHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(declineHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['declined-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('declineHubspotProjectCandidate', mutation);
};

export const useRestoreHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(restoreHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['declined-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('restoreHubspotProjectCandidate', mutation);
};

export const useApproveHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(approveHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('approveHubspotProjectCandidate', mutation);
};

export const useReviewHubspotProjectCandidate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(reviewHubspotProjectCandidate, {
    onSuccess: ({ hubspotProject: { dealId } }, { contactId, hubspotProjectId }) => {
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
      queryClient.invalidateQueries(['candidate-logs', contactId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });
  return prepareMutation('reviewHubspotProjectCandidate', mutation);
};
