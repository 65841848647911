import { useState, useEffect } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, SxProps, Typography } from '@mui/material';

export interface SelectableItem {
  title: string;
  count: number;
  selected: boolean;
}

interface Props {
  items: SelectableItem[];
  sx?: SxProps;
  onChange?: (selectedItems: SelectableItem[]) => void;
  isLoading?: boolean;
}

const StatusesList: React.FC<Props> = ({ items, sx = {}, onChange = () => {}, isLoading }) => {
  const [listItems, setListItems] = useState<SelectableItem[]>(items);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  const handleSelectionChange = (title: string, originalSelected: boolean): void => {
    const updatedItems = listItems.map((item) =>
      item.title === title ? { ...item, selected: !originalSelected } : item
    );
    setListItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <Box sx={sx}>
      {listItems.map(({ title, count, selected }) => (
        <Box
          key={title}
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&&: hover': {
              backgroundColor: (theme) => theme.palette.lightBackground.primary,
            },
            ...(selected
              ? {
                  backgroundColor: (theme) => theme.palette.lightBackground.primary,
                }
              : {}),
            px: 1,
          }}
        >
          <FormControlLabel
            label={
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  letterSpacing: '0.15px',
                }}
              >
                {title}
              </Typography>
            }
            control={
              <Checkbox
                checked={selected}
                color="primary"
                onClick={() => handleSelectionChange(title, selected)}
                size="small"
              />
            }
            sx={{
              flexGrow: 1,
            }}
            color="primary"
          />
          <Typography sx={{ pr: 1 }}>{!isLoading ? count : <CircularProgress size={12} />}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default StatusesList;
